import { jsx as _jsx } from "react/jsx-runtime";
import { useKratosTranslate } from './helpers';
import Button from '../Button';
import { useSearchParams } from 'next/navigation';
var NodeAnchor = function (_a) {
    var node = _a.node, attributes = _a.attributes;
    var t = useKratosTranslate();
    var searchParams = useSearchParams();
    var onClick = function (e) {
        e.stopPropagation();
        e.preventDefault();
        // At the end of the verification flow, we want to respect the `return_to`
        // query parameter.
        var returnTo = searchParams.get('return_to');
        if (attributes.id === 'continue' && returnTo) {
            window.location.href = returnTo;
            return;
        }
        window.location.href = attributes.href;
    };
    return _jsx(Button, { onClick: onClick, children: t.nodeLabel(node) });
};
export default NodeAnchor;
