var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { getNodeId, isUiNodeInputAttributes } from '@ory/integrations/ui';
import Messages from './Messages';
import Node from './Node';
var Flow = function (_a) {
    var flow = _a.flow, onSubmit = _a.onSubmit, hideGlobalMessages = _a.hideGlobalMessages, only = _a.only, context = _a.context;
    var _b = useState({
        isLoading: false,
        values: {},
    }), state = _b[0], setState = _b[1];
    var handleSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        var form, body, formData, hasSubmitter, method, combined, _a;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    // Prevent all native handlers
                    event.stopPropagation();
                    event.preventDefault();
                    // Prevent double submission!
                    if (state.isLoading) {
                        return [2 /*return*/];
                    }
                    form = event.currentTarget;
                    if (!(form && form instanceof HTMLFormElement)) return [3 /*break*/, 5];
                    formData = new FormData(form);
                    // map the entire form data to JSON for the request body
                    body = Object.fromEntries(formData);
                    hasSubmitter = function (evt) { return 'submitter' in evt; };
                    // We need the method specified from the name and value of the submitt
                    // button. When multiple submit buttons are present, the clicked one's
                    // value is used.
                    if (hasSubmitter(event.nativeEvent)) {
                        method = event.nativeEvent.submitter;
                        body = __assign(__assign({}, body), (_b = {}, _b[method.name] = method.value, _b));
                    }
                    setState(__assign(__assign({}, state), { isLoading: true }));
                    combined = Object.fromEntries(Object.keys(body)
                        .map(function (k) {
                        var values = state.values;
                        var b = body;
                        return [k, values[k] !== undefined ? values[k] : b[k]];
                    })
                        .map(function (_a) {
                        var k = _a[0], v = _a[1];
                        // Remove leading and trailing whitespace from strings
                        if (typeof v === 'string') {
                            return [k, v.trim()];
                        }
                        return [k, v];
                    }));
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, onSubmit(combined)];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 4];
                case 3:
                    _a = _c.sent();
                    return [3 /*break*/, 4];
                case 4:
                    setState(__assign(__assign({}, state), { isLoading: false }));
                    _c.label = 5;
                case 5: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        var values = {};
        flow === null || flow === void 0 ? void 0 : flow.ui.nodes.forEach(function (node) {
            // This only makes sense for text nodes
            if (isUiNodeInputAttributes(node.attributes)) {
                if (node.attributes.type === 'button' ||
                    node.attributes.type === 'submit') {
                    // In order to mimic real HTML forms, we need to skip setting the
                    // value for buttons as the button value will (in normal HTML forms)
                    // only trigger if the user clicks it.
                    return;
                }
                values[node.attributes.name] = node.attributes.value;
            }
        });
        setState(function (s) { return (__assign(__assign({}, s), { values: values })); });
    }, [flow === null || flow === void 0 ? void 0 : flow.ui.nodes]);
    if (!flow) {
        // No flow set yet? It's probably still loading ...
        return null;
    }
    var nodes = (flow.ui.nodes || []).filter(function (_a) {
        var group = _a.group;
        if (!only) {
            return true;
        }
        return group === 'default' || group === only;
    });
    return (_jsxs("form", { action: flow.ui.action, method: flow.ui.method, onSubmit: handleSubmit, "data-isloading": state.isLoading, className: "flex flex-col gap-4 group/flow data-[isLoading=true]:saturate-50", children: [!hideGlobalMessages && _jsx(Messages, { messages: flow.ui.messages }), nodes.map(function (node, k) {
                var id = getNodeId(node);
                return (_jsx(Node, { disabled: state.isLoading, node: node, value: state.values[id], dispatchSubmit: handleSubmit, setValue: function (value) { return __awaiter(void 0, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            setState(__assign(__assign({}, state), { values: __assign(__assign({}, state.values), (_a = {}, _a[id] = value, _a)) }));
                            return [2 /*return*/];
                        });
                    }); }, context: context }, "".concat(id, "-").concat(k)));
            })] }));
};
export default Flow;
