import { Configuration, FrontendApi, } from '@ory/client';
// Using the API proxy at `/api/.ory/` (useful for development)
var withProxyConfig = {
    basePath: '/api/.ory',
    baseOptions: {
        withCredentials: true,
    },
};
// Browser will directly request from KRATOS_PULIC_URL, i.e.
// `https://auth.monil.io`, which is what we want for production.
var directConfig = {
    basePath: process.env.NEXT_PUBLIC_KRATOS_PUBLIC_URL,
    baseOptions: {
        withCredentials: true,
    },
};
var serverConfig = {
    basePath: process.env.ORY_SDK_URL,
};
export var config = process.env.NEXT_RUNTIME === 'nodejs'
    ? serverConfig
    : process.env.NEXT_PUBLIC_KRATOS_PUBLIC_URL
        ? directConfig
        : withProxyConfig;
export default new FrontendApi(new Configuration(config));
