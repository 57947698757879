import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
// NodeInputLanguage is a special hidden input which takes its value from the
// currently resolved langauge in the i18n system. Since the i18n gets
// initialized semi-intelligently (via browser headers), and can be changed
// manually, we treat this as a proxy for the user's choice without explicitly
// asking. This allows us to store the language for use in e.g. emails.
var NodeInputLanguage = function (_a) {
    var attributes = _a.attributes;
    var i18n = useTranslation().i18n;
    return (_jsx("input", { type: "hidden", name: attributes.name, id: attributes.name, value: i18n.resolvedLanguage }));
};
export default NodeInputLanguage;
