import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '../Button';
import { useKratosTranslate } from './helpers';
import Spinner from '../Spinner';
var NodeInputSubmit = function (_a) {
    var node = _a.node, attributes = _a.attributes, disabled = _a.disabled;
    var t = useKratosTranslate();
    return (_jsxs(Button, { name: attributes.name, value: attributes.value || '', disabled: disabled, children: [_jsx(Spinner, { className: "hidden group-data-[isLoading=true]/flow:inline" }), _jsx("span", { className: "group-data-[isLoading=true]/flow:opacity-70", children: t.nodeLabel(node) })] }));
};
export default NodeInputSubmit;
