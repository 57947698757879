import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useKratosTranslate } from './helpers';
import NodeMessage from './NodeMessage';
import { Trans, useTranslation } from 'react-i18next';
import Checkbox from '../Checkbox';
var NodeInputAcceptedToS = function (_a) {
    var node = _a.node, attributes = _a.attributes, value = _a.value, setValue = _a.setValue;
    var t = useKratosTranslate();
    var tt = useTranslation('kratos').t;
    var onChange = function (e) {
        setValue(e.target.checked ? 'monil_tos_v1' : 'NOT_CHECKED');
    };
    return (_jsxs("div", { className: "w-full flex flex-col", children: [_jsxs("div", { className: "inline-flex relative flex-row justify-between gap-4", children: [_jsx(Checkbox, { id: attributes.name, name: attributes.name, "aria-label": t.nodeLabel(node), onChange: onChange, checked: value === 'monil_tos_v1' }), _jsx("label", { htmlFor: attributes.name, children: _jsx(Trans, { t: tt, i18nKey: "Accepted ToS", components: [
                                _jsx("a", { href: tt('privacyLink.href'), className: "underline", target: "_blank", rel: "noreferrer" }, "privacyLink"),
                                _jsx("a", { href: tt('termsLink.href'), className: "underline", target: "_blank", rel: "noreferrer" }, "termsLink"),
                            ] }) })] }), node.messages.map(function (msg, k) { return (_jsx(NodeMessage, { type: msg.type, children: t.nodeMessage(node, msg) }, "".concat(msg.id, "-").concat(k))); })] }));
};
export default NodeInputAcceptedToS;
