var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
var Input = function (_a) {
    var label = _a.label, name = _a.name, error = _a.error, props = __rest(_a, ["label", "name", "error"]);
    return (_jsxs("div", { className: "inline-flex relative group", "data-error": String(error), children: [_jsxs("div", { className: "inline-flex relative z-0 align-center w-full", children: [_jsx("input", __assign({ name: name, "aria-label": label, placeholder: " ", className: "rounded-md w-full px-4 pt-6 pb-2 border-0 appearance-none bg-transparent text-slate-500 disabled:bg-slate-100 disabled:border-0 disabled:text-slate-400" }, props)), _jsx("div", { className: "flex absolute inset-0 -z-10 border-2 border-slate-200 rounded-md group-data-[error=true]:border-red-400" })] }), _jsx("label", { htmlFor: name, className: "absolute z-10 pointer-events-none text-xs inset-x-4 inset-y-2 group-has-[:placeholder-shown]:text-base group-has-[:placeholder-shown]:inset-4 group-has-[:focus]:inset-y-2 group-has-[:focus]:!text-xs transition-all text-slate-400 group-has-[:disabled]:text-slate-400", children: label })] }));
};
export default Input;
