import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useKratosTranslate } from './helpers';
import NodeMessage from './NodeMessage';
import Checkbox from '../Checkbox';
var NodeInputCheckbox = function (_a) {
    var node = _a.node, attributes = _a.attributes, setValue = _a.setValue, disabled = _a.disabled;
    var t = useKratosTranslate();
    var hasError = useMemo(function () {
        return !!node.messages.find(function (_a) {
            var type = _a.type;
            return type === 'error';
        });
    }, [node.messages]);
    return (_jsxs("div", { className: "flex flex-col", "data-error": String(hasError), children: [_jsxs("div", { className: "inline-flex relative group flex-row justify-between gap-4", children: [_jsx(Checkbox, { "aria-label": t.nodeLabel(node), name: attributes.name, defaultChecked: attributes.value, onChange: function (e) {
                            return setValue(e.target.checked);
                        }, disabled: attributes.disabled || disabled }), _jsx("label", { htmlFor: attributes.name, className: "ml-4", children: t.nodeLabel(node) })] }), node.messages.map(function (msg, k) { return (_jsx(NodeMessage, { type: msg.type, children: t.nodeMessage(node, msg) }, "".concat(msg.id, "-").concat(k))); })] }));
};
export default NodeInputCheckbox;
