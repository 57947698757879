var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
export var handleFlowError = function (err, router, flowType, resetFlow) { return __awaiter(void 0, void 0, void 0, function () {
    var apiErr, response, redirectTo;
    var _a, _b;
    return __generator(this, function (_c) {
        apiErr = err;
        response = apiErr.response;
        switch ((_a = response === null || response === void 0 ? void 0 : response.data.error) === null || _a === void 0 ? void 0 : _a.id) {
            case 'session_inactive':
                router.push("/login?return_to=".concat(window.location.href));
                return [2 /*return*/, null];
            case 'session_aal2_required':
                if (response.data.redirect_browser_to) {
                    redirectTo = new URL(response.data.redirect_browser_to);
                    if (flowType === 'settings') {
                        redirectTo.searchParams.set('return_to', window.location.href);
                    }
                    // 2FA is enabled and enforced, but user did not perform 2fa yet!
                    window.location.href = redirectTo.toString();
                    return [2 /*return*/, null];
                }
                router.push("/login?aal=aal2&return_to=".concat(window.location.href));
                return [2 /*return*/, null];
            case 'session_already_available':
                // User is already signed in, let's redirect them home!
                router.push('/');
                return [2 /*return*/, null];
            case 'session_refresh_required':
                // We need to re-authenticate to perform this action
                if (response.data.redirect_browser_to) {
                    window.location.href = response === null || response === void 0 ? void 0 : response.data.redirect_browser_to;
                }
                return [2 /*return*/, null];
            case 'self_service_flow_return_to_forbidden':
                // The return_to address is forbidden
                // @TODO: show error message
                console.error('The return_to address is not allowed');
                resetFlow(undefined);
                router.push("/".concat(flowType));
                return [2 /*return*/, null];
            case 'self_service_flow_expired':
                // The flow expired, let's request a new one
                // @TODO: show error message
                console.error('The interaction expired, please fill out the form again');
                resetFlow(undefined);
                router.push("/".concat(flowType));
                return [2 /*return*/, null];
            case 'security_csrf_violation':
                // A CSRF violation occurred. Best to just refresh the flow!
                console.error('A security violation was detected, please fill out the form again.');
                resetFlow(undefined);
                router.push("/".concat(flowType));
                return [2 /*return*/, null];
            case 'security_identity_mismatch':
                // The requested item was intended for someone else. Let's request a new flow ...
                resetFlow(undefined);
                router.push("/".concat(flowType));
                return [2 /*return*/, null];
            case 'browser_location_change_required':
                // Kratos asked us to point the user to this url
                if (response.data.redirect_browser_to) {
                    window.location.href = response.data.redirect_browser_to;
                }
                return [2 /*return*/, null];
        }
        switch ((_b = apiErr.response) === null || _b === void 0 ? void 0 : _b.status) {
            case 410:
                // The flow expired, let's request a new one.
                resetFlow(undefined);
                router.push("/".concat(flowType));
                return [2 /*return*/, null];
        }
        // Unable to handle the error
        return [2 /*return*/, apiErr];
    });
}); };
