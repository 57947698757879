import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
export var useOnload = function (attributes) {
    useEffect(function () {
        if (attributes.onload) {
            var intervalHandle_1 = callWebauthnFunction(attributes.onload);
            return function () {
                window.clearInterval(intervalHandle_1);
            };
        }
    }, [attributes]);
};
export var callWebauthnFunction = function (functionBody) {
    var run = new Function(functionBody);
    var intervalHandle = window.setInterval(function () {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (window.__oryWebAuthnInitialized) {
            run();
            window.clearInterval(intervalHandle);
        }
    }, 100);
    return intervalHandle;
};
export var useKratosTranslate = function () {
    var t = useTranslation('kratos').t;
    return useMemo(function () { return ({
        nodeLabel: function (node) {
            var _a, _b, _c;
            switch ((_a = node.meta.label) === null || _a === void 0 ? void 0 : _a.id) {
                case 1070002:
                    return t(node.meta.label.context.title);
            }
            return t("".concat((_b = node.meta.label) === null || _b === void 0 ? void 0 : _b.id), (_c = node.meta.label) === null || _c === void 0 ? void 0 : _c.context);
        },
        nodeMessage: function (node, text) {
            if (node.attributes.type === 'tel' &&
                text.id === 4000001) {
                return t('invalidTel', {
                    tel: node.attributes.value,
                });
            }
            else if (node.attributes.name === 'traits.accepted_tos' &&
                text.id === 4000001) {
                return t('invalidToS');
            }
            return t("".concat(text.id), text.context);
        },
        message: function (text) {
            return t("".concat(text.id), text.context);
        },
    }); }, [t]);
};
