import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useKratosTranslate } from './Node/helpers';
import { Trans, useTranslation } from 'react-i18next';
import Link from 'next/link';
export var Message = function (_a) {
    var message = _a.message;
    var t = useKratosTranslate();
    var tt = useTranslation('kratos').t;
    var type = message.type;
    // Excplicitly handle case where the user has not verified their email so that we can give them
    // a link to the verification page.
    var msg = message.id === 4000010 ? (_jsx(Trans, { t: tt, i18nKey: "4000010", components: [
            _jsx(Link, { className: "text-slate-600 underline", href: "/verification" }, "verificationLink"),
        ] })) : (t.message(message));
    return (_jsxs("p", { "data-type": type, className: "p-4 rounded-md data-[type=error]:bg-red-100 data-[type=error]:text-red-600 data-[type=info]:bg-blue-100 data-[type=info]:text-slate-600 data-[type=success]:bg-green-100", children: [_jsx("span", { className: "pr-4", children: type === 'error' ? '❌' : type === 'success' ? '✅' : '☝️' }), msg] }));
};
var Messages = function (_a) {
    var messages = _a.messages;
    if (!messages) {
        return null;
    }
    return (_jsx("div", { children: messages.map(function (message) { return (_jsx(Message, { message: message }, message.id)); }) }));
};
export default Messages;
