import { jsx as _jsx } from "react/jsx-runtime";
import { isUiNodeAnchorAttributes, isUiNodeImageAttributes, isUiNodeInputAttributes, isUiNodeScriptAttributes, isUiNodeTextAttributes, } from '@ory/integrations/ui';
import NodeInput from './NodeInput';
import NodeAnchor from './NodeAnchor';
export var Node = function (_a) {
    var node = _a.node, value = _a.value, setValue = _a.setValue, disabled = _a.disabled, dispatchSubmit = _a.dispatchSubmit, context = _a.context;
    if (isUiNodeImageAttributes(node.attributes)) {
        return _jsx("p", { children: "NodeImage" });
    }
    if (isUiNodeScriptAttributes(node.attributes)) {
        return _jsx("p", { children: "NodeScript" });
    }
    if (isUiNodeTextAttributes(node.attributes)) {
        return _jsx("p", { children: "NodeText" });
    }
    if (isUiNodeAnchorAttributes(node.attributes)) {
        return _jsx(NodeAnchor, { node: node, attributes: node.attributes });
    }
    if (isUiNodeInputAttributes(node.attributes)) {
        return (_jsx(NodeInput, { dispatchSubmit: dispatchSubmit, value: value, setValue: setValue, node: node, disabled: disabled, attributes: node.attributes, context: context }));
    }
    return null;
};
export default Node;
