var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import NodeInputHidden from './NodeInputHidden';
import NodeInputDefault from './NodeInputDefault';
import NodeInputSubmit from './NodeInputSubmit';
import NodeInputCheckbox from './NodeInputCheckbox';
import NodeInputAcceptedToS from './NodeInputAcceptedToS';
import NodeInputLanguage from './NodeInputLanguage';
import NodeInputPhoneNumber from './NodeInputPhoneNumber';
import NodeInputInformational from './NodeInputInformational';
var NodeInput = function (props) {
    var attributes = props.attributes;
    // Specific fields which should be treated differently
    if (attributes.type !== 'hidden') {
        switch (attributes.name) {
            case 'traits.accepted_tos':
                if (props.context === 'settings' && attributes.value) {
                    return _jsx(NodeInputHidden, __assign({}, props));
                }
                return _jsx(NodeInputAcceptedToS, __assign({}, props));
            case 'traits.language':
                return _jsx(NodeInputLanguage, __assign({}, props));
        }
    }
    switch (attributes.type) {
        case 'hidden':
            // in the login context, show the 'identifier' field as informational
            // content to help the user to understand why they only need to input
            // their password.
            if (props.context === 'login' && attributes.name === 'identifier') {
                return _jsx(NodeInputInformational, __assign({}, props));
            }
            // render a hidden input field
            return _jsx(NodeInputHidden, __assign({}, props));
        case 'checkbox':
            return _jsx(NodeInputCheckbox, __assign({}, props));
        case 'button':
            // Render a button
            return _jsx("p", { children: "Button" });
        case 'submit':
            // Render the submit button
            return _jsx(NodeInputSubmit, __assign({}, props));
        case 'tel':
            return _jsx(NodeInputPhoneNumber, __assign({}, props));
    }
    // Render a generic text input field
    return _jsx(NodeInputDefault, __assign({}, props));
};
export default NodeInput;
