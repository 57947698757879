var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import NodeInputHidden from './NodeInputHidden';
var NodeInputInformational = function (props) {
    return (_jsxs(_Fragment, { children: [props.attributes.name === 'identifier' && (_jsxs("p", { className: "p-4 rounded-md bg-slate-50 text-slate-400", children: [_jsx("span", { className: "pr-4", children: "\uD83E\uDEAA" }), props.attributes.value] })), _jsx(NodeInputHidden, __assign({}, props))] }));
};
export default NodeInputInformational;
