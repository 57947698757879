import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import * as phone from 'libphonenumber-js';
import { useKratosTranslate } from './helpers';
import NodeMessage from './NodeMessage';
import Input from '../Input';
var countryFlagEmoji = function (countryCode) {
    var codePoints = countryCode
        .toUpperCase()
        .split('')
        .map(function (char) { return 127397 + char.charCodeAt(0); });
    return String.fromCodePoint.apply(String, codePoints);
};
var NodeInputPhoneNumber = function (_a) {
    var _b;
    var attributes = _a.attributes, node = _a.node, value = _a.value, setValue = _a.setValue;
    var t = useKratosTranslate();
    var _c = useState(), defaultCountryCode = _c[0], setDefaultCountryCode = _c[1];
    var _d = useState({
        shownValue: value ? String(value) : '',
    }), state = _d[0], setState = _d[1];
    // Try to auto-set the user's country code based on the ipapi.co API
    useEffect(function () {
        fetch('https://ipapi.co/json')
            .then(function (res) { return res.json(); })
            .then(function (data) { return (data === null || data === void 0 ? void 0 : data.country_code) || 'US'; })
            .catch(function () { return 'US'; })
            .then(function (code) { return setDefaultCountryCode(code.toUpperCase()); });
    }, []);
    var onFieldValueChanged = function (fieldValue) {
        var ayt = new phone.AsYouType(defaultCountryCode);
        ayt.input(fieldValue);
        var number = ayt.getNumber();
        if (fieldValue === '') {
            setValue('');
        }
        else if (number) {
            setValue(ayt.getNumberValue());
        }
        setState({
            shownValue: (number === null || number === void 0 ? void 0 : number.formatInternational()) || fieldValue,
            number: number,
        });
    };
    useEffect(function () {
        if (state.shownValue === '' && value) {
            onFieldValueChanged(value ? String(value) : '');
        }
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps
    var onChange = function (e) {
        onFieldValueChanged(e.target.value);
    };
    return (_jsxs("div", { className: "w-full flex flex-col", children: [_jsx("input", { type: "hidden", name: attributes.name, value: value ? String(value) : '', id: attributes.name }), _jsx(Input, { type: "tel", value: state.shownValue, onChange: onChange, label: ((_b = state.number) === null || _b === void 0 ? void 0 : _b.country)
                    ? "".concat(countryFlagEmoji(state.number.country), " ").concat(state.number.country, " (+").concat(state.number.countryCallingCode, ")")
                    : t.nodeLabel(node) }), node.messages.map(function (msg, k) { return (_jsx(NodeMessage, { type: msg.type, children: t.nodeMessage(node, msg) }, "".concat(msg.id, "-").concat(k))); })] }));
};
export default NodeInputPhoneNumber;
