import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import Input from '../Input';
import { useKratosTranslate } from './helpers';
import NodeMessage from './NodeMessage';
var NodeInputDefault = function (_a) {
    var node = _a.node, attributes = _a.attributes, _b = _a.value, value = _b === void 0 ? '' : _b, setValue = _a.setValue, disabled = _a.disabled;
    var t = useKratosTranslate();
    // Some attributes have dynamic JavaScript – this is for example required for WebAuthn
    var onClick = function () {
        // This section is only used for WebAuthn. The script is loaded via a
        // <script> node and the functions are available on the global window
        // level. Unfortunately, there is currently no better way than executing
        // eval / function here at the moment.
        if (attributes.onclick) {
            var run = new Function(attributes.onclick);
            run();
        }
    };
    var hasError = useMemo(function () {
        return !!node.messages.find(function (_a) {
            var type = _a.type;
            return type === 'error';
        });
    }, [node.messages]);
    return (_jsxs("div", { className: "w-full flex flex-col", children: [_jsx(Input, { label: t.nodeLabel(node), error: hasError, onClick: onClick, onChange: function (e) { return setValue(e.target.value); }, type: attributes.type, name: attributes.name, value: value, disabled: attributes.disabled || disabled }), node.messages.map(function (msg, k) { return (_jsx(NodeMessage, { type: msg.type, children: t.nodeMessage(node, msg) }, "".concat(msg.id, "-").concat(k))); })] }));
};
export default NodeInputDefault;
